.autoScaleMobBlock[data-v-9e99a100] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-9e99a100] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-9e99a100] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-9e99a100] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-9e99a100] {
  z-index: 1000;
}
.icomoon[data-v-9e99a100] {
  vertical-align: bottom;
}
.RangeSlider *[data-v-9e99a100] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.RangeSlider[data-v-9e99a100] {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  background: white;
}
.RangeSlider-inner[data-v-9e99a100] {
  position: relative;
  -ms-flex: 1;
      flex: 1;
}
.RangeSlider-separator[data-v-9e99a100] {
  display: none;
}
.RangeSlider-separator[data-v-9e99a100]:not(:last-child) {
  display: block;
  width: 0;
  height: 30px;
  border-right: 1px solid #e6e6e6;
  margin: 9px 0;
}
.RangeSlider-content[data-v-9e99a100] {
  font: normal 15px/18px Roboto;
  color: #bcbcbc;
}
.RangeSlider-content label[data-v-9e99a100] {
  margin-right: 11px;
  color: #3c4043;
}
.RangeSlider-slider[data-v-9e99a100] {
  position: absolute;
  bottom: 0;
  left: 18px;
  right: 18px;
  margin-bottom: -10px;
}
.RangeSlider-slider.isMobile[data-v-9e99a100] {
  margin-bottom: -25px;
  right: auto;
  width: calc((100% - 36px) * 1.73333333) !important;
  -ms-transform: scale(0.57692308);
      transform: scale(0.57692308);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
}
.RangeSlider-content--simple[data-v-9e99a100] {
  padding: 15px;
}
.RangeSlider-content--manual[data-v-9e99a100] {
  padding: 0 0 0 15px;
  display: -ms-flexbox;
  display: flex;
}
.RangeSlider-content--manual label[data-v-9e99a100] {
  margin-right: 7px;
}
.RangeSlider-content--manual div[data-v-9e99a100] {
  width: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: baseline;
      align-items: baseline;
}
.RangeSlider-content--manual input[data-v-9e99a100] {
  -ms-flex: 1;
      flex: 1;
  font: inherit;
  padding: 15px 0 15px 0px;
  border: none;
  margin: 0;
  min-width: 0;
  width: 0;
}
.RangeSlider-content--manual input[data-v-9e99a100]:focus:-ms-input-placeholder {
  opacity: 1;
  color: #e6e6e6;
}
.RangeSlider-content--manual input[data-v-9e99a100]:focus::placeholder {
  opacity: 1;
  color: #e6e6e6;
}
.Dot[data-v-9e99a100] {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 3px solid #fff;
  margin: 3px;
  background: #b09783;
  cursor: pointer;
  position: relative;
}
.Dot.isMobile[data-v-9e99a100]:after {
  content: " ";
  position: absolute;
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}
.RangeSlider-slider:hover .Dot[data-v-9e99a100] {
  width: 12px;
  height: 12px;
  border: 3px solid #fff;
  margin: 0;
}
.Dot[data-v-9e99a100]:active {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #fff !important;
  margin: 0 !important;
}
.Dot.isMobile[data-v-9e99a100] {
  width: 20.8px !important;
  height: 20.8px !important;
  border: 5.2px solid #fff !important;
  margin: 0;
}
.Dot.isMobile[data-v-9e99a100]:active {
  width: 27.73333333px !important;
  height: 27.73333333px !important;
  border: 1.73333333px solid #fff !important;
  margin: 0 !important;
}
