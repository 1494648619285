.autoScaleMobBlock[data-v-3949afe7] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-3949afe7] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-3949afe7] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-3949afe7] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-3949afe7] {
  z-index: 1000;
}
.icomoon[data-v-3949afe7] {
  vertical-align: bottom;
}
.MultiSelectView[data-v-3949afe7] {
  color: #3c4043;
}
.MultiSelectView[data-v-3949afe7],
.MultiSelectView[data-v-3949afe7] .multiselect__single {
  font: 500 14px/16px Roboto;
}
.isMobile .MultiSelectView[data-v-3949afe7] .multiselect__single {
  font: 500 24.26666667px / 27.73333333px Roboto;
}
.MultiSelectView.multiselect--active[data-v-3949afe7] .multiselect__placeholder {
  display: block;
}
.MultiSelectView[data-v-3949afe7] .multiselect__placeholder,
.MultiSelectView[data-v-3949afe7] .multiselect__single {
  padding: 0;
  margin: 0;
  min-height: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MultiSelectView[data-v-3949afe7] .multiselect__tags {
  min-height: 0;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 16px 15px;
  padding-right: 40px;
  transition: border-color 0.3s;
}
.isMobile .MultiSelectView[data-v-3949afe7] .multiselect__tags {
  border-radius: 6.93333333px;
  padding: 27.73333333px 26px;
  padding-right: 69.33333333px;
}
.MultiSelectView[data-v-3949afe7]:not(.searchable) .multiselect__tags,
.MultiSelectView[data-v-3949afe7]:not(.searchable) .caret {
  cursor: pointer;
}
.MultiSelectView.multiselect--active[data-v-3949afe7] .multiselect__tags {
  border: 2px solid #c0ac9c;
  padding: 14px;
  border-radius: 4px;
  padding-right: 39px;
  z-index: 100;
  position: relative;
}
.isMobile .MultiSelectView.multiselect--active[data-v-3949afe7] .multiselect__tags {
  border-width: 3.46666667px;
  padding: 24.26666667px;
  border-radius: 6.93333333px;
  padding-right: 67.6px;
}
.MultiSelectView[data-v-3949afe7] .placeholder {
  color: #bcbcbc;
}
.MultiSelectView[data-v-3949afe7] .multiselect__content {
  font: normal 13px/15px Roboto;
}
.MultiSelectView[data-v-3949afe7] .caret {
  border: none;
  position: absolute;
  top: 50%;
  right: 21px;
  width: auto;
  height: auto;
  margin-top: -8px;
  transition: transform 0.3s;
  z-index: 1;
}
.isOpen .MultiSelectView[data-v-3949afe7] .caret {
  z-index: 101;
}
.isMobile .MultiSelectView[data-v-3949afe7] .caret {
  right: 36.4px;
  margin-top: -8px;
}
.MultiSelectView[data-v-3949afe7] .caret svg {
  stroke: #7e7e7e;
  stroke-width: 2px;
  width: 14px;
  height: 9px;
  fill: none;
}
.isMobile .MultiSelectView[data-v-3949afe7] .caret svg {
  stroke-width: 2px;
  width: 24.26666667px;
  height: 15.6px;
}
.MultiSelectView.multiselect--active[data-v-3949afe7] .caret {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
}
.MultiSelectView.multiselect--active[data-v-3949afe7] .caret svg {
  stroke: #b09783;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option {
  padding: 14px 16px;
  min-height: 0;
  outline: none;
  font: normal 13px/15px Roboto;
}
.isMobile .MultiSelectView[data-v-3949afe7] .multiselect__option {
  padding: 24.26666667px 27.73333333px;
  font: normal 22.53333333px / 26px Roboto;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option svg {
  width: 19px;
  height: 18px;
  margin-bottom: -3px;
  margin-top: -10px;
  margin-right: 10px;
}
.isMobile .MultiSelectView[data-v-3949afe7] .multiselect__option svg {
  width: 32.93333333px;
  height: 31.2px;
  margin-bottom: -5.2px;
  margin-top: -17.33333333px;
  margin-right: 17.33333333px;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option .svg--cb-checked {
  display: none;
}
.MultiSelectView[data-v-3949afe7] .multiselect__element:not(:last-child) {
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
}
.MultiSelectView[data-v-3949afe7] .multiselect__option::after {
  display: none !important;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option--highlight {
  background: white;
  color: black;
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.2), rgba(230, 230, 230, 0.2)), #ffffff !important;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option--selected {
  background: white;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option--selected .svg--cb-checked {
  display: inline-block;
}
.MultiSelectView[data-v-3949afe7] .multiselect__option--selected .svg--cb-unchecked {
  display: none;
}
.MultiSelectView[data-v-3949afe7] .multiselect__content-wrapper {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 2px;
}
