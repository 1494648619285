.autoScaleMobBlock {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen {
  z-index: 1000;
}
.autoScaleMobZoom {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen {
  z-index: 1000;
}
.icomoon {
  vertical-align: bottom;
}
.ObjectSimpleRequestForm-static {
  padding: 50px 70px 0;
  max-width: 800px;
}
.ObjectSimpleRequestForm-wrapper {
  border-top: 1px solid #e3ddd1;
  text-align: center;
}
.ObjectSimpleRequestForm-wrapper .header {
  margin-top: 10px;
  font-weight: 400;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: normal;
  font-family: MyslC;
}
.ObjectSimpleRequestForm-wrapper .description {
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: normal;
  font-family: MyslC;
}
.ObjectSimpleRequestForm-wrapper .phone {
  margin-top: 10px;
  font-weight: 700;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: normal;
  font-family: MyslC;
}
.ObjectSimpleRequestForm-wrapper .phone * {
  color: black !important;
}
.smallButton {
  font: 500 14px/16px Roboto !important;
  padding: 12px 15px !important;
}
