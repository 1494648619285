.autoScaleMobBlock[data-v-6a8bde0e] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-6a8bde0e] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-6a8bde0e] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-6a8bde0e] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-6a8bde0e] {
  z-index: 1000;
}
.icomoon[data-v-6a8bde0e] {
  vertical-align: bottom;
}
.CallbackForm[data-v-6a8bde0e] {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 35px;
}
.row[data-v-6a8bde0e] {
  margin-bottom: 16px;
}
.header[data-v-6a8bde0e] {
  font: 40px MysLC;
  margin-bottom: 12px;
}
.description[data-v-6a8bde0e] {
  font: 500 18px/24px Roboto;
  margin-bottom: 30px;
}
.svg--back[data-v-6a8bde0e] {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  fill: #b09783;
}
.CallbackForm.isMobile .svg--back[data-v-6a8bde0e] {
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  top: 27.73333333px;
  left: 27.73333333px;
}
.CallbackForm.isMobile .Content[data-v-6a8bde0e] {
  height: 644px;
}
