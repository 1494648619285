.FilterDirectionSwitcherDesktop[data-v-30a58a05] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  text-align: right;
  padding: 0 48px;
  border-bottom: 1px solid #e6e6e6;
}
.tab[data-v-30a58a05] {
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7e7e7e;
  border-bottom: 3px solid transparent;
  padding: 32px 0 27px;
  margin-bottom: -1px;
  text-align: center;
}
.tab[data-v-30a58a05]:hover {
  color: #3c4043;
}
.tab.current[data-v-30a58a05] {
  color: #b09783;
  border-bottom-color: #b09783;
}
