.autoScaleMobBlock[data-v-18c136a7] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-18c136a7] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-18c136a7] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-18c136a7] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-18c136a7] {
  z-index: 1000;
}
.icomoon[data-v-18c136a7] {
  vertical-align: bottom;
}
.row[data-v-18c136a7] {
  margin-bottom: 16px;
}
