.autoScaleMobBlock[data-v-11904339] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-11904339] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-11904339] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-11904339] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-11904339] {
  z-index: 1000;
}
.icomoon[data-v-11904339] {
  vertical-align: bottom;
}
.CheckView[data-v-11904339] {
  font: 500 16px/19px Roboto;
  color: #3c4043;
  cursor: pointer;
}
input[data-v-11904339] {
  display: none;
}
svg[data-v-11904339] {
  width: 19px;
  height: 18px;
  margin-bottom: -3px;
  margin-top: -10px;
  margin-right: 10px;
}
input:checked ~ .svg--cb-unchecked[data-v-11904339] {
  display: none;
}
input:not(:checked) ~ .svg--cb-checked[data-v-11904339] {
  display: none;
}
.autoScaleMob[data-v-11904339] {
  box-sizing: border-box;
  padding-top: 13px;
}
.captionText[data-v-11904339] {
  font: normal 14px/17px MysLC;
  color: #3c4043;
  cursor: pointer;
}
[data-v-11904339] a {
  color: #b09783;
}
