.autoScaleMobBlock[data-v-6378435c] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-6378435c] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-6378435c] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-6378435c] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-6378435c] {
  z-index: 1000;
}
.icomoon[data-v-6378435c] {
  vertical-align: bottom;
}
.CookieLaw[data-v-6378435c] {
  z-index: 9999999999;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.23);
  color: #3c4043;
  font-size: 16px;
  width: auto;
}
.CookieLaw a[data-v-6378435c] {
  color: #b09783;
}
.CookieLaw .Btn[data-v-6378435c] {
  width: auto;
}
.CookieLaw span[data-v-6378435c] {
  margin-right: 50px;
}
.CookieLaw.isDesktop[data-v-6378435c] {
  left: 120px;
  bottom: 50px;
  right: 480px;
}
.CookieLaw.isDesktop .CookieLaw-inner[data-v-6378435c] {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: row;
      flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: auto;
      align-self: auto;
  height: auto;
  margin: 0;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.CookieLaw.isMobile .CookieLaw-buttonWrapper[data-v-6378435c] {
  width: 200px;
  float: right;
  margin-top: 30px;
}
.CookieLaw.isMobile span[data-v-6378435c] {
  margin-right: 30px;
  font-size: 30px;
}
