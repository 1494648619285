.autoScaleMobBlock[data-v-feec7f32] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-feec7f32] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-feec7f32] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-feec7f32] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-feec7f32] {
  z-index: 1000;
}
.icomoon[data-v-feec7f32] {
  vertical-align: bottom;
}
.FilterLinks-content[data-v-feec7f32] {
  text-align: center;
  color: #a78a73;
}
.FilterLinks-content .FilterLinks-selectedValue a[data-v-feec7f32] {
  color: red;
  text-decoration: none;
}
.FilterLinks-popup .h1[data-v-feec7f32] {
  text-align: center;
}
.FilterLinks-popup .items[data-v-feec7f32] {
  text-align: left;
}
.FilterLinks-popup .item[data-v-feec7f32] {
  color: #a78a73;
  display: inline;
  white-space: nowrap;
}
.FilterLinks-popup .item[data-v-feec7f32]::after {
  content: " ";
}
.FilterLinks.isDesktop .FilterLinks-content[data-v-feec7f32] {
  padding: 70px 30px 30px;
}
.FilterLinks.isDesktop .FilterLinks-content .title[data-v-feec7f32] {
  font: 500 16px/19px Roboto;
  color: #3c4043;
}
.FilterLinks.isDesktop .FilterLinks-content .items[data-v-feec7f32] {
  padding-top: 20px;
}
.FilterLinks.isDesktop .FilterLinks-content .item[data-v-feec7f32] {
  font: 500 14px/19px Roboto;
  display: inline-block;
  padding: 3px 10px;
}
.FilterLinks.isDesktop.popup-content[data-v-feec7f32] {
  max-height: 99vh;
  overflow: auto;
  padding: 0;
  min-height: 200px;
  text-align: left;
}
.FilterLinks.isDesktop.popup-content[data-field="metro"][data-v-feec7f32] {
  min-width: 1000px !important;
}
.FilterLinks.isDesktop .popup-outer[data-v-feec7f32] {
  overflow: auto;
}
.FilterLinks.isDesktop .FilterLinks-popup .h1[data-v-feec7f32] {
  font: 400 36px/42px MysLC;
}
.FilterLinks.isDesktop .FilterLinks-popup .items[data-v-feec7f32] {
  padding: 30px 40px 0;
}
.FilterLinks.isDesktop .FilterLinks-popup .item[data-v-feec7f32] {
  font: 500 14px/30px Roboto;
  padding: 13px 0;
  margin-right: 10px;
}
.FilterLinks.isDesktop .FilterLinks-popup .itemheader[data-v-feec7f32] {
  font: 400 26px/32px MysLC;
  margin-top: 1em;
}
.FilterLinks.isMobile .FilterLinks-content[data-v-feec7f32] {
  padding: 50px 0;
}
.FilterLinks.isMobile .FilterLinks-content .title[data-v-feec7f32] {
  font: 500 calc(16px * 1.73333333) / calc(19px * 1.73333333) Roboto;
}
.FilterLinks.isMobile .FilterLinks-content .items[data-v-feec7f32] {
  padding-top: 34px;
}
.FilterLinks.isMobile .FilterLinks-content .item[data-v-feec7f32] {
  font: 500 calc(16px * 1.73333333) / calc(19px * 1.73333333) Roboto;
  display: inline-block;
  padding: 3px 10px;
}
.FilterLinks.isMobile .FilterLinks-content .itemheader[data-v-feec7f32] {
  font: 400 calc(26px * 1.73333333) / calc(32px * 1.73333333) MysLC;
  margin-top: 1em;
}
.FilterLinks.isMobile .popup-center[data-v-feec7f32] {
  padding: 100px 0;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: auto;
}
.FilterLinks.isMobile .FilterLinks-popup .h1[data-v-feec7f32] {
  font: 400 calc(36px * 1.73333333) / calc(42px * 1.73333333) MysLC;
}
.FilterLinks.isMobile .FilterLinks-popup .items[data-v-feec7f32] {
  padding: 30px 60px 0;
}
.FilterLinks.isMobile .FilterLinks-popup .item[data-v-feec7f32] {
  font: 500 calc(14px * 1.73333333) / calc(30px * 1.73333333) Roboto;
  padding: 13px 0;
  margin-right: 10px;
}
.svg--close[data-v-feec7f32] {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  padding: 5px;
  z-index: 1000;
  fill: #b09783;
}
.FilterLinks.isMobile .svg--close[data-v-feec7f32] {
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
  top: 27.73333333px;
  right: 27.73333333px;
}
