.autoScaleMobBlock[data-v-1362916c] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-1362916c] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-1362916c] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-1362916c] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-1362916c] {
  z-index: 1000;
}
.icomoon[data-v-1362916c] {
  vertical-align: bottom;
}
.CallbackSelect[data-v-1362916c] {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 67px;
}
.CallbackSelect-phone[data-v-1362916c] {
  position: absolute;
  font: 500 31.2px / 41.6px Roboto;
  top: 31.2px;
  left: 31.2px;
}
.CallbackSelect-phone[data-v-1362916c] a {
  color: black;
  text-decoration: none;
}
.CallbackSelect-photo[data-v-1362916c] {
  width: 100px;
  height: 100px;
  -ms-flex-item-align: center;
      align-self: center;
}
.CallbackSelect-caption[data-v-1362916c] {
  font: 500 18px/24px Roboto;
  margin: 20px 0 32px;
}
.CallbackSelect[data-v-1362916c] .Btn {
  border-radius: 28px;
  margin-bottom: 8px;
}
.CallbackSelect[data-v-1362916c] .Btn span {
  font-size: 18px;
  line-height: 26px;
}
.Whatsapp[data-v-1362916c] .Btn {
  background: #43d55b !important;
}
.Whatsapp[data-v-1362916c] .Btn svg {
  width: 24px;
  height: 25px;
  margin: -5px 10px -5px 0;
}
.Telegram[data-v-1362916c] .Btn {
  background: #33a9e5 !important;
}
.Telegram[data-v-1362916c] .Btn svg {
  width: 24px;
  height: 21px;
  margin: -5px 10px -5px 0;
}
.Call[data-v-1362916c] .Btn {
  background: #000 !important;
}
.Call[data-v-1362916c] .Btn svg {
  width: 23px;
  height: 23px;
  margin: -5px 10px -5px 0;
}
.CallbackSelect.isMobile .Content[data-v-1362916c] {
  height: 644px;
}
