.autoScaleMobBlock[data-v-145e90fa] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-145e90fa] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-145e90fa] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-145e90fa] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-145e90fa] {
  z-index: 1000;
}
.icomoon[data-v-145e90fa] {
  vertical-align: bottom;
}
.burgerMenu[data-v-145e90fa] {
  position: fixed;
  top: 0;
  left: 80px;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: scroll;
  min-width: 1260px;
}
.burgerMenu.preparing[data-v-145e90fa],
.burgerMenu.overflowhidden[data-v-145e90fa] {
  overflow: hidden;
}
.burgerMenu .isvg-logo[data-v-145e90fa] {
  width: 316px;
  height: 38px;
  position: absolute;
  top: 50px;
  margin-top: -20px;
  left: 39px;
  z-index: 1;
}
.burgerMenu-topLinks[data-v-145e90fa] {
  margin-left: 370px;
  text-align: right;
  padding: 47px 120px 30px 0;
}
.burgerMenu-topLinks a[data-v-145e90fa] {
  display: inline-block;
  line-height: 21;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  font-family: Roboto;
  color: white;
  white-space: nowrap;
  border-bottom: 1px solid rgba(151, 151, 151, 0.47);
  text-decoration: none;
  transition: opacity 0.3s, border-color 0.3s;
  margin-left: 39px;
  padding-bottom: 6px;
  margin-bottom: 20px;
}
.burgerMenu-topLinks a[data-v-145e90fa]:hover {
  border-bottom-color: transparent;
}
.burgerMenu-menuContent[data-v-145e90fa] {
  max-width: 1440px;
  margin: 0 auto;
}
.burgerMenu-opTypes[data-v-145e90fa] {
  margin: 2px 0 0 39px;
}
.burgerMenu-opTypes span[data-v-145e90fa] {
  line-height: 30;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  font-family: Roboto;
  color: #444444;
  transition: color 0.3s;
  margin-right: 53px;
}
.burgerMenu-opTypes span.current[data-v-145e90fa] {
  color: white;
}
.burgerMenu-opTypes span[data-v-145e90fa]:not(.current) {
  cursor: pointer;
}
.burgerMenu-opTypes span[data-v-145e90fa]:not(.current):hover {
  color: #777777;
}
.burgerMenu-directions[data-v-145e90fa] {
  font-size: 0;
  margin: 43px 0 0 40px;
  display: inline-block;
  border: 1px solid #444444;
}
.burgerMenu-directions .sep[data-v-145e90fa] {
  display: none;
}
@media screen and (max-width: 1500px) {
.burgerMenu-directions .sep[data-v-145e90fa] {
    display: block;
}
.burgerMenu-directions .item[data-v-145e90fa]:nth-child(3) {
    margin-right: -1px;
}
.burgerMenu-directions .item[data-v-145e90fa]:nth-child(3):after {
    display: none;
}
.burgerMenu-directions .item[data-v-145e90fa]:nth-child(5) {
    margin-left: -1px;
}
.burgerMenu-directions .item[data-v-145e90fa]:nth-child(1),
  .burgerMenu-directions .item[data-v-145e90fa]:nth-child(2),
  .burgerMenu-directions .item[data-v-145e90fa]:nth-child(3) {
    margin-bottom: 0;
}
.burgerMenu-directions .item[data-v-145e90fa]:nth-child(1):before,
  .burgerMenu-directions .item[data-v-145e90fa]:nth-child(2):before,
  .burgerMenu-directions .item[data-v-145e90fa]:nth-child(3):before {
    content: ' ';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #444444;
    transition: background 0.3s;
}
}
.burgerMenu-directions .item[data-v-145e90fa] {
  line-height: 50;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
  font-family: Roboto;
  color: #CACACA;
  transition: color 0.3s, background 0.3s;
  width: 223px;
  display: inline-block;
  text-align: center;
  margin: -1px 0;
  position: relative;
  text-decoration: none;
}
.burgerMenu-directions .item[data-v-145e90fa]:first-child {
  margin-left: -1px;
}
.burgerMenu-directions .item[data-v-145e90fa]:last-child {
  margin-right: -1px;
}
.burgerMenu-directions .item[data-v-145e90fa]:after {
  content: ' ';
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  right: -1px;
  background: #444444;
  transition: background 0.3s;
}
.burgerMenu-directions .item[data-v-145e90fa]:last-child:after {
  background: transparent;
}
.burgerMenu-directions .item.current[data-v-145e90fa] {
  color: black;
  background: white;
  z-index: 1;
}
.burgerMenu-directions .item.current[data-v-145e90fa]:after,
.burgerMenu-directions .item.current[data-v-145e90fa]:before {
  background: transparent;
}
.burgerMenu-directions .item[data-v-145e90fa]:not(.current) {
  cursor: pointer;
}
.burgerMenu-directions .item[data-v-145e90fa]:not(.current):hover {
  color: white;
}
.burgerMenu-page[data-v-145e90fa] {
  padding: 71px 39px;
}
.burgerMenu-page.preparing[data-v-145e90fa] {
  visibility: hidden;
}
.burgerMenu-page .column[data-v-145e90fa] {
  width: 33%;
  display: inline-block;
  vertical-align: top;
}
.burgerMenu-group[data-v-145e90fa] {
  margin-bottom: 35px;
  padding-right: 43px;
}
.burgerMenu-group-title[data-v-145e90fa] {
  color: white;
  margin-bottom: 19px;
  max-width: 270px;
  line-height: 25;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  font-family: Roboto;
  cursor: pointer;
  transition: color 0.3s;
  border-bottom: 1px solid transparent;
  position: relative;
}
.burgerMenu-group-title .icon[data-v-145e90fa] {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 5px;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s;
}
.burgerMenu-group-title[data-v-145e90fa]:hover {
  color: #CACACA;
}
.burgerMenu-group-title:hover .icon[data-v-145e90fa] {
  background-image: url('/static/front/svg/burger-more-hover.svg');
}
.burgerMenu-group.collapsed .burgerMenu-group-items[data-v-145e90fa] {
  display: none;
}
.burgerMenu-group.collapsed .burgerMenu-group-title[data-v-145e90fa] {
  border-bottom-color: #444444;
  margin-bottom: -4px;
  padding-bottom: 23px;
}
.burgerMenu-group.collapsed .burgerMenu-group-title .icon[data-v-145e90fa] {
  opacity: 1;
}
.burgerMenu-group.collapsed:hover .burgerMenu-group-title[data-v-145e90fa] {
  border-bottom-color: #CACACA;
}
.burgerMenu-group-more[data-v-145e90fa] {
  display: none;
  color: white;
  line-height: 18;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  font-family: Roboto;
  margin-top: -2px;
  margin-bottom: 2px;
  cursor: pointer;
  transition: color 0.3s;
}
.burgerMenu-group-more .icon[data-v-145e90fa] {
  width: 17px;
  height: 17px;
  position: relative;
  top: 4px;
  margin-left: 8px;
}
.burgerMenu-group-more[data-v-145e90fa]:hover {
  color: #CACACA;
}
.burgerMenu-group-more:hover .icon[data-v-145e90fa] {
  background-image: url('/static/front/svg/burger-more-hover.svg');
}
.burgerMenu-item.hidden ~ .burgerMenu-group-more[data-v-145e90fa] {
  display: inline-block;
}
.burgerMenu-item[data-v-145e90fa] {
  margin-bottom: 9px;
}
.burgerMenu-item a[data-v-145e90fa] {
  display: inline-block;
  color: #E6E6E6;
  line-height: 20;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: Roboto;
  text-decoration: none;
  border-bottom: 1px solid #979797;
  transition: color 0.3s, border-color 0.3s;
}
.burgerMenu-item a[data-v-145e90fa]:hover {
  border-bottom-color: transparent;
  color: white;
}
.burgerMenu-item.hidden[data-v-145e90fa] {
  display: none;
}
