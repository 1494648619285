[class^="icomoon-"],
[class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "welhome-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icomoon-close:before {
  content: "\e90a";
}
.icomoon-geo-okrug:before {
  content: "\e908";
}
.icomoon-geo-area:before {
  content: "\e900";
}
.icomoon-geo-highway:before {
  content: "\e901";
}
.icomoon-geo-jk-1:before {
  content: "\e902";
}
.icomoon-geo-jk-2:before {
  content: "\e903";
}
.icomoon-geo-jk-3:before {
  content: "\e904";
}
.icomoon-geo-jk-4:before {
  content: "\e905";
}
.icomoon-geo-jk:before {
  content: "\e906";
}
.icomoon-geo-metro:before {
  content: "\e907";
}
.icomoon-geo-street:before {
  content: "\e908";
}
.icomoon-option-selected-indicator:before {
  content: "\e909";
  color: #b09783;
}
