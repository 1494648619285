@import "vue-select/dist/vue-select.css";
.autoScaleMobBlock[data-v-68a8aef0] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-68a8aef0] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-68a8aef0] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-68a8aef0] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-68a8aef0] {
  z-index: 1000;
}
.icomoon[data-v-68a8aef0] {
  vertical-align: bottom;
}
.SelectView[data-v-68a8aef0] {
  position: relative;
}
.SelectView:not(.border) .SelectViewInput[data-v-68a8aef0] .vs__dropdown-toggle {
  border-color: transparent !important;
  border-radius: 0 !important;
  background: transparent;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-toggle {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 1px;
  font: 500 14px/16px Roboto;
  transition: border-color 0.3s;
  z-index: 10;
  background: white;
  position: relative;
}
.SelectViewWrapper.isMobile .SelectViewInput[data-v-68a8aef0] .vs__dropdown-toggle {
  border-width: 1.73333333px;
  padding: 1.73333333px;
  border-radius: 6.93333333px;
  font: 500 24.26666667px/27.73333333px Roboto;
}
.u-ErrorView .SelectViewInput[data-v-68a8aef0] .vs__dropdown-toggle {
  border-color: red;
}
.SelectViewInput.vs--open[data-v-68a8aef0] .vs__dropdown-toggle {
  padding: 0;
  border: 2px solid #c0ac9c;
}
.SelectViewWrapper.isMobile .SelectViewInput.vs--open[data-v-68a8aef0] .vs__dropdown-toggle {
  padding: 0;
  border-width: 3.46666667px;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-menu {
  overflow: auto !important;
  padding: 0;
  background: white;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 4px;
  border: none;
  z-index: 100;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-menu li[role="option"] {
  padding: 0;
  background: none;
  color: #3c4043;
  font: 13px/15px Roboto;
  padding: 14px 16px;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-menu li[role="option"]:not(:last-child) {
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
}
.SelectViewWrapper.isMobile .SelectViewInput[data-v-68a8aef0] .vs__dropdown-menu li[role="option"] {
  padding: 24.26666667px 27.73333333px;
  font: 22.53333333px/26px Roboto;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-menu li[role="option"].vs__dropdown-option--highlight {
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.2), rgba(230, 230, 230, 0.2)), #ffffff;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-menu li[role="option"].vs__dropdown-option--selected {
  color: #b09783;
}
.SelectViewInput .svg--open-indicator[data-v-68a8aef0] {
  transition: transform 0.3s;
  stroke: #7e7e7e;
}
.SelectViewInput.vs--open[data-v-68a8aef0] .svg--open-indicator {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
  stroke: #b09783;
}
.SelectViewInput[data-v-68a8aef0] .vs__no-options {
  font: normal 12px/30px Roboto;
  letter-spacing: 0;
  color: #bcbcbc;
}
.SelectViewWrapper.isMobile .SelectViewInput[data-v-68a8aef0] .vs__no-options {
  font: 20.8px/52px Roboto;
}
.SelectViewInput[data-v-68a8aef0] .vs__selected-tag {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.SelectViewInput[data-v-68a8aef0] .vs__selected-options {
  padding: 15px;
}
.SelectViewWrapper.isMobile .SelectViewInput[data-v-68a8aef0] .vs__selected-options {
  padding: 26px;
}
.SelectViewInput[data-v-68a8aef0] .vs__selected {
  color: #3c4043;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  opacity: 1;
  font: 500 14px/16px Roboto;
}
.SelectViewWrapper.isMobile .SelectViewInput[data-v-68a8aef0] .vs__selected {
  font: 500 24.26666667px/27.73333333px Roboto;
}
.SelectViewInput[data-v-68a8aef0] .vs__search {
  color: #3c4043;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  opacity: 1;
  font: 500 14px/16px Roboto;
}
.SelectViewWrapper.isMobile .SelectViewInput[data-v-68a8aef0] .vs__search {
  font: 500 24.26666667px/27.73333333px Roboto;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-option svg svg {
  float: right;
}
.SelectViewInput[data-v-68a8aef0] .vs__dropdown-option:not(.vs__dropdown-option--selected) svg {
  display: none;
}
.SelectViewInput[data-v-68a8aef0] .vs__actions {
  padding: 0;
}
.custom-open-indicator[data-v-68a8aef0] {
  padding: 0 19px;
}
.SelectViewWrapper.isMobile .custom-open-indicator[data-v-68a8aef0] {
  padding: 0 32.93333333px;
}
.svg--option-selected-indicator[data-v-68a8aef0] {
  float: right;
  width: 14px;
  height: 10px;
}
.SelectViewWrapper.isMobile .svg--option-selected-indicator[data-v-68a8aef0] {
  width: 24.26666667px;
  height: 17.33333333px;
}
.svg--open-indicator[data-v-68a8aef0] {
  stroke: black;
  stroke-width: 2px;
  fill: none;
  width: 14px;
  height: 9px;
}
.SelectViewWrapper.isMobile .svg--open-indicator[data-v-68a8aef0] {
  width: 24.26666667px;
  height: 15.6px;
}
.SelectViewOption[data-v-68a8aef0] {
  pointer-events: none;
}
.u-ShowErrors .SelectView.u-ErrorView .SelectViewInput[data-v-68a8aef0] .dropdown-toggle {
  border-color: red !important;
}
