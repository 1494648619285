@import "vue-select/dist/vue-select.css";
.autoScaleMobBlock[data-v-ca07ae84] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-ca07ae84] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-ca07ae84] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-ca07ae84] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-ca07ae84] {
  z-index: 1000;
}
.icomoon[data-v-ca07ae84] {
  vertical-align: bottom;
}
.GeoSelectViewWrapper.isMobile[data-v-ca07ae84] {
  height: 85px;
  position: relative;
}
.GeoSelectViewWrapper.isMobile[data-v-ca07ae84] * {
  transition: none !important;
  transition-delay: none !important;
  -webkit-backface-visibility: hidden !important;
          backface-visibility: hidden !important;
}
.GeoSelectViewWrapper.isMobile.isOpen[data-v-ca07ae84] .vs__actions {
  display: none;
}
.GeoSelectViewWrapper.isMobile[data-v-ca07ae84] .vs__fade-enter-active,
.GeoSelectViewWrapper.isMobile[data-v-ca07ae84] .vs__fade-leave-active {
  transition: none;
}
.GeoSelectViewWrapper.isMobile.isOpen .GeoSelectViewWrapperInner[data-v-ca07ae84] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 12px 16px 12px 0;
  height: auto;
  box-sizing: border-box;
}
.GeoSelectViewWrapper.isMobile.isOpen .GeoSelectViewWrapperInner .GeoSelectView[data-v-ca07ae84] {
  -ms-flex: 1;
      flex: 1;
}
.GeoSelectViewWrapper.isMobile.isOpen .GeoSelectViewWrapperInnerDummy[data-v-ca07ae84] {
  visibility: hidden;
  pointer-events: none;
}
.GeoSelectViewWrapper.isMobile.isOpen[data-v-ca07ae84] .vs__dropdown-toggle {
  z-index: 1001;
}
.GeoSelectViewWrapper.isMobile[data-v-ca07ae84] .vs__dropdown-menu {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  max-height: none !important;
  height: auto !important;
  padding-bottom: 330px;
  box-sizing: border-box;
  background: white;
  border-radius: 0;
}
.GeoSelectViewWrapper.isMobile .GeoSelectViewMobClose[data-v-ca07ae84] {
  padding: 12px 12px 10px 16px;
}
.GeoSelectViewWrapper.isMobile .GeoSelectViewMobClose .svg-close[data-v-ca07ae84] {
  width: 24px;
  height: 24px;
}
.HeaderSelect[data-v-ca07ae84] {
  font: 500 14px/20px Roboto;
  color: #7e7e7e;
  padding-left: 8px;
  border-bottom: 1px solid #e6e6e6;
}
.GeoSelectViewWrapper.needScaleFontOnMob .HeaderSelect[data-v-ca07ae84] {
  font: 500 24px / 35px Roboto;
}
.HeaderSelectItem[data-v-ca07ae84] {
  cursor: pointer;
  padding: 12px 12px 8px;
  display: inline-block;
}
.HeaderSelectItem[data-v-ca07ae84]:hover {
  color: #3c4043;
}
.HeaderSelectItem--selected[data-v-ca07ae84] {
  color: #b09783 !important;
  border-bottom: 2px solid #b09783;
  margin-bottom: -1px;
}
.GeoSelectViewSelectedOption[data-v-ca07ae84] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
}
.GeoSelectViewSelectedOption .title[data-v-ca07ae84] {
  font: normal 13px/15px Roboto;
  color: #b09783;
  -ms-flex: 1;
      flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  padding-right: 10px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewSelectedOption .title[data-v-ca07ae84] {
  font: normal 22.53333333px / 26px Roboto;
}
.GeoSelectViewSelectedOption .close[data-v-ca07ae84] {
  display: inline-block;
  padding: 4px;
  margin: -7px;
  cursor: pointer;
}
.GeoSelectViewSelectedOption .close:hover .icomoon-close[data-v-ca07ae84] {
  color: #3c4043;
}
.GeoSelectViewSelectedOption .icomoon-type[data-v-ca07ae84] {
  width: 24px;
  height: 24px;
  margin: -6px 2px -4px -2px;
  color: #b09783;
  display: inline-block;
  font-size: 24px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewSelectedOption .icomoon-type[data-v-ca07ae84] {
  font-size: 41.6px;
}
.GeoSelectViewSelectedOption .icomoon-close[data-v-ca07ae84] {
  width: 16px;
  height: 16px;
  color: #bcbcbc;
  display: inline-block;
  font-size: 16px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewSelectedOption .icomoon-close[data-v-ca07ae84] {
  font-size: 27.73333333px;
}
.GeoSelectViewOptionHeader[data-v-ca07ae84] {
  font: 500 10px/12px Roboto;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #bcbcbc;
  position: relative;
  top: 8px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewOptionHeader[data-v-ca07ae84] {
  font: 500 17.33333333px / 20.8px Roboto;
}
.GeoSelectView[data-v-ca07ae84] {
  position: relative;
}
.GeoSelectViewLabel[data-v-ca07ae84] {
  display: block;
  font: bold 18px/30px Roboto;
  letter-spacing: -0.025em;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewLabel[data-v-ca07ae84] {
  font: bold 31.2px / 52px Roboto;
}
.GeoSelectView:not(.border) .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-toggle {
  border-color: transparent !important;
  border-radius: 0 !important;
  background: transparent;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-toggle {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 1px;
  font: 500 14px/16px Roboto;
  transition: border-color 0.3s;
  z-index: 10;
  background: white;
  position: relative;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-toggle {
  font: 500 24.26666667px / 27.73333333px Roboto;
}
.GeoSelectViewInput.vs--open[data-v-ca07ae84] .vs__dropdown-toggle {
  padding: 0;
  border: 2px solid #c0ac9c;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu {
  overflow: auto !important;
  padding: 0;
  background: white;
  border-radius: 4px;
  margin-top: 4px;
  border: none;
}
.GeoSelectViewWrapper.isDesktop .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu {
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu {
  box-shadow: none;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu li[role="option"] {
  padding: 0;
  background: none;
  color: #3c4043;
  font: 13px/15px Roboto;
  padding: 13px 16px 12px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu li[role="option"] {
  font: normal 22.53333333px / 26px Roboto;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu li[role="option"]:not(:last-child) {
  border-bottom: 1px solid rgba(230, 230, 230, 0.2);
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu li[role="option"].vs__dropdown-option--highlight {
  background: linear-gradient(0deg, rgba(230, 230, 230, 0.2), rgba(230, 230, 230, 0.2)), #ffffff;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-menu li[role="option"].vs__dropdown-option--selected {
  color: #b09783;
}
.GeoSelectViewInput .open_indicator[data-v-ca07ae84] {
  transition: transform 0.3s;
  stroke: #7e7e7e;
}
.GeoSelectViewInput.vs--open[data-v-ca07ae84] .open_indicator {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
  stroke: #b09783;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__no-options {
  letter-spacing: 0;
  font: normal 12px / 30px Roboto;
  color: #bcbcbc;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__no-options {
  font: normal 20.8px / 52px Roboto;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected-tag {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected-options {
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 46px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__selected-options {
  padding: 9px 15px;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected-options input {
  margin-left: 10px;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected-options input.noOptions {
  margin-left: 0;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected {
  color: #3c4043;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  opacity: 1;
  background: none;
  font: 500 14px/16px Roboto;
  margin-right: 10px;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__selected {
  font: 500 24.26666667px / 27.73333333px Roboto;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected + .vs__selected {
  margin-left: -10px;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__selected + .vs__selected:before {
  content: ",  ";
  margin-right: 5px;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search {
  color: #3c4043;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  opacity: 1;
  font: 500 14px/16px Roboto;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__search {
  font: 500 24.26666667px / 27.73333333px Roboto;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search:-ms-input-placeholder {
  color: #bcbcbc;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search::placeholder {
  color: #bcbcbc;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search:focus:-ms-input-placeholder {
  opacity: 1;
  color: #e6e6e6;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search:focus::placeholder {
  opacity: 1;
  color: #e6e6e6;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search.span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__search.span.noOptions {
  color: #bcbcbc;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option .icomoon-checked {
  float: right;
  width: 14px;
  height: 10px;
  font-size: 14px;
  display: inline-block;
  fill: red;
  margin: 0px 0 -4px 0;
  vertical-align: bottom;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option .icomoon-checked {
  font-size: 24.26666667px;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option .icomoon-type {
  width: 24px;
  height: 24px;
  margin: -6px 2px -1px -2px;
  color: #bcbcbc;
  font-size: 24px;
  display: inline-block;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option .icomoon-type {
  font-size: 41.6px;
  margin-bottom: 1px;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option:not(.vs__dropdown-option--selected) .icomoon-checked {
  display: none;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option.vs__dropdown-option--highlight .icomoon-type {
  color: #7e7e7e;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__dropdown-option.vs__dropdown-option--selected .icomoon-type {
  color: #b09783;
}
.GeoSelectViewInput[data-v-ca07ae84] .vs__actions {
  padding: 0;
}
.custom-open-indicator[data-v-ca07ae84] {
  padding: 0 19px;
}
.option-selected-indicator[data-v-ca07ae84] {
  float: right;
}
.GeoSelectViewLabel + .GeoSelectViewInput[data-v-ca07ae84] {
  margin: 20px 0 0;
}
.GeoSelectViewEdit[data-v-ca07ae84] {
  position: absolute;
  top: 3px;
  right: 0;
  font: 12px/30px Roboto;
  color: #b09783;
  transition: opacity 0.2s;
  cursor: pointer;
}
.GeoSelectViewWrapper.needScaleFontOnMob .GeoSelectViewEdit[data-v-ca07ae84] {
  font: 500 20.8px / 52px Roboto;
}
.GeoSelectViewEdit[data-v-ca07ae84]:hover {
  opacity: 0.7;
}
.GeoSelectViewOption[data-v-ca07ae84] {
  pointer-events: none;
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
