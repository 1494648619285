.autoScaleMobBlock[data-v-3e817ed2] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-3e817ed2] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-3e817ed2] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-3e817ed2] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-3e817ed2] {
  z-index: 1000;
}
.icomoon[data-v-3e817ed2] {
  vertical-align: bottom;
}
.isDesktop .Row[data-v-3e817ed2] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}
.isDesktop .Row[data-v-3e817ed2]:not(:last-child) {
  margin-bottom: 32px;
}
.isDesktop .RowItem[data-v-3e817ed2]:not(:last-child) {
  margin-right: 16px;
}
.isDesktop .RowItem--Results[data-v-3e817ed2] {
  -ms-flex-direction: row;
      flex-direction: row;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.isDesktop.Filters[data-v-3e817ed2] {
  width: 944px;
  margin: 0 auto;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 111;
  margin-top: -82px;
  padding-bottom: 48px;
  margin-bottom: 80px;
}
.isDesktop .FiltersContent[data-v-3e817ed2] {
  margin: 30px 48px 0;
}
.isDesktop .ResultsCount[data-v-3e817ed2] {
  margin-right: 32px;
}
.isMobile.Filters[data-v-3e817ed2] {
  /*width: 375px;
    background-color: yellow;
    min-height: 600px;
    transform: scale(650/375);
    transform-origin: 0 0;*/
  margin: 45.06666667px 27.73333333px 0;
}
.isMobile .RowItem[data-v-3e817ed2],
.isMobile .MobileTypeSelect[data-v-3e817ed2] {
  margin-bottom: 27.73333333px;
}
.isMobile .RowItem.priceOpened[data-v-3e817ed2] {
  z-index: 1;
  position: relative;
}
.isMobile .ResultsCount[data-v-3e817ed2] {
  text-align: center;
  display: block;
  font: 500 27px/32px Roboto;
  margin-bottom: 27.73333333px;
}
.ResultsCount[data-v-3e817ed2] {
  font: 500 16px/19px Roboto;
  color: #3c4043;
  white-space: nowrap;
}
.PriceCurrency[data-v-3e817ed2] {
  width: 60px;
  margin: -1px;
}
.PriceCurrency.isOpen[data-v-3e817ed2] {
  z-index: 1000;
}
.PriceCurrency[data-v-3e817ed2] .vs__selected-options {
  margin-right: -34px;
  margin-left: -5px;
}
.PriceCurrency[data-v-3e817ed2] .SelectViewOption {
  min-width: 15px;
  font: 500 16px/16px Roboto;
}
.PriceCurrency[data-v-3e817ed2] .vs__dropdown-menu {
  max-width: 60px !important;
  min-width: 0;
}
.PriceCurrency[data-v-3e817ed2] .vs__dropdown-option--selected {
  display: none;
}
.PriceCurrency[data-v-3e817ed2] .vs__dropdown-option--highlight {
  color: black !important;
}
