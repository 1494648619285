.autoScaleMobBlock[data-v-ab8303dc] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-ab8303dc] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-ab8303dc] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-ab8303dc] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-ab8303dc] {
  z-index: 1000;
}
.icomoon[data-v-ab8303dc] {
  vertical-align: bottom;
}
.Btn[data-v-ab8303dc] {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: #b09783;
  font: 500 16px/20px Roboto;
  color: white;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  padding: 15px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
.Btn.isDesktop[data-v-ab8303dc]:hover {
  background: #c0ac9c;
}
.Btn.blocked[data-v-ab8303dc] {
  pointer-events: none;
}
.Btn.disabled[data-v-ab8303dc] {
  pointer-events: none;
  opacity: 0.5;
}
.Btn span.text[data-v-ab8303dc] {
  display: block;
  height: 20px;
}
.Btn span.spinner[data-v-ab8303dc] {
  display: block;
  margin: 7px auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.2);
  height: 6px;
  width: 6px;
  animation: dotFlashing-data-v-ab8303dc 0.5s 0.25s infinite linear alternate;
  position: relative;
}
.Btn span.spinner[data-v-ab8303dc]::before,
.Btn span.spinner[data-v-ab8303dc]::after {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.2);
  height: 6px;
  width: 6px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.Btn span.spinner[data-v-ab8303dc]::before {
  animation: dotFlashing-data-v-ab8303dc 0.5s infinite alternate;
  left: -10px;
}
.Btn span.spinner[data-v-ab8303dc]::after {
  animation: dotFlashing-data-v-ab8303dc 0.5s 0.5s infinite alternate;
  left: 10px;
}
.dot[data-v-ab8303dc] {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.2);
  height: 6px;
  width: 6px;
}
.dot-flashing[data-v-ab8303dc] {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.2);
  height: 6px;
  width: 6px;
  animation: dotFlashing-data-v-ab8303dc 0.5s 0.25s infinite linear alternate;
  position: relative;
}
.dot-flashing[data-v-ab8303dc]::before,
.dot-flashing[data-v-ab8303dc]::after {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.2);
  height: 6px;
  width: 6px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing[data-v-ab8303dc]::before {
  animation: dotFlashing-data-v-ab8303dc 0.5s infinite alternate;
  left: -10px;
}
.dot-flashing[data-v-ab8303dc]::after {
  animation: dotFlashing-data-v-ab8303dc 0.5s 0.5s infinite alternate;
  left: 10px;
}
@keyframes dotFlashing-data-v-ab8303dc {
0% {
    background-color: rgba(255, 255, 255, 0.2);
}
100% {
    background-color: #ffffff;
}
}
.Btn.pulse[data-v-ab8303dc]:after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 50%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  animation: pulse-data-v-ab8303dc 2.5s ease-out infinite;
  opacity: 0;
  margin-left: -25px;
}
@keyframes pulse-data-v-ab8303dc {
0% {
    transform: scale(0);
    opacity: 0.5;
}
60% {
    transform: scale(5);
    opacity: 0;
}
to {
    transform: scale(5);
    opacity: 0;
}
}
