.autoScaleMobBlock[data-v-0e6c1eef] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  position: relative;
}
.autoScaleMob[data-v-0e6c1eef] {
  width: 57.69230769%;
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
  height: 85px;
  position: relative;
}
.autoScaleMob.isOpen[data-v-0e6c1eef] {
  z-index: 1000;
}
.autoScaleMobZoom[data-v-0e6c1eef] {
  position: relative;
  zoom: 173.33333333%;
}
.autoScaleMobZoom.isOpen[data-v-0e6c1eef] {
  z-index: 1000;
}
.icomoon[data-v-0e6c1eef] {
  vertical-align: bottom;
}
.CallbackShow[data-v-0e6c1eef] {
  fill: #3c4043;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 30px;
  bottom: 50px;
  transition: fill 0.2s;
  cursor: pointer;
}
.CallbackShow[data-v-0e6c1eef]:hover {
  fill: #b09783;
}
.Callback.isDesktop .Backdrop[data-v-0e6c1eef] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.Content[data-v-0e6c1eef] {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  bottom: 0;
  right: 0;
  width: 416px;
  background-color: #fff;
  padding: 10px 20px;
}
.Callback.isMobile .Content[data-v-0e6c1eef] {
  left: 0;
  width: auto;
}
.svg--close[data-v-0e6c1eef] {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  padding: 5px;
  z-index: 1000;
  fill: #b09783;
}
.Callback.isMobile .svg--close[data-v-0e6c1eef] {
  -ms-transform: scale(1.73333333);
      transform: scale(1.73333333);
  -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
  top: 27.73333333px;
  right: 27.73333333px;
}
